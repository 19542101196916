<template>
  <div style="padding: 20px">
    <div ref="echartsContainer" style="width: 100%; height: 450px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "roundn",
  props: {
    titleName: {
      type: String,
      default: "实时休假",
    },
    centerTage: {
      type: String,
      default: "4.1%",
    },
    centerName: {
      type: String,
      default: "请假率",
    },
    allPel: {
      type: String,
      default: "总人数\u0020\u0020\u0020\u00202000",
    },
    dataValue: {
      type: Array,
      default: () => [
        {
          value: 2000,
          name: "非请假人数 2000",
          itemStyle: { color: "#68a6ff" },
        },
        {
          value: 86,
          name: "请假人数 86",
          itemStyle: { color: "#fde932" },
        },
        {
          value: 18,
          name: "销假人数 18",
          itemStyle: { color: "#ee7c51" },
        },
      ],
    },
  },
  mounted() {
    var myChart = echarts.init(this.$refs.echartsContainer);
    myChart.setOption({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      // toolbox: {
      //   feature: {
      //     dataView: { show: true, readOnly: false },
      //     magicType: { show: true, type: ["line", "bar"] },
      //     restore: { show: true },
      //     saveAsImage: { show: true },
      //   },
      // },
      legend: {
        // 设置图例显示在顶部
        top: "top",
        data: ["请假时长", "请假申请次数"], // 更新图例名称
      },
      xAxis: [
        {
          type: "category",
          data: [
            "07.01",
            "07.02",
            "07.03",
            "07.04",
            "07.05",
            "07.06",
            "07.07",
            "07.08",
            "07.09",
            "07.10",
            "07.11",
            "07.12",
          ],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          //   name: "Precipitation",
          min: 0,
          max: 7,
          interval: 1,
          axisLabel: {
            formatter: "{value} ",
          },
        },
        {
          type: "value",
          //   name: "Temperature",
          min: 0,
          max: 14,
          interval: 2,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: "请假时长",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + " ml";
            },
          },
          data: [1, 2, 3, 4, 5, 4, 2, 4, 4, 7, 7, 3],
          itemStyle: {
            color: "#39cf05", // 更改为青色
          },
          barWidth: 40, // 添加这一行来指定柱子的宽度
        },
        {
          name: "请假申请次数",
          type: "line", // 更改为平滑曲线
          smooth: true, // 添加平滑曲线
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + " °C";
            },
          },
          data: [
            2.6, 5.7, 8.4, 11.2, 14.0, 14.0, 5.7, 7.0, 12.6, 7.0, 5.8, 7.0,
          ],
          lineStyle: {
            color: "#87cefa", // 更改为天蓝色
          },
        },
      ],
    });
  },
};
</script>
