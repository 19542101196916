<template>
  <div class="dashboard-editor-container">
    <!-- 条件 -->
    <div class="screen">
      <el-form
        :inline="true"
        ref="formInline"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="学院">
          <dept-select
            v-model="formInline.college"
            :defaultLabel="formInline.college"
            placeholder="请选择院系"
            valueField="no"
          />
        </el-form-item>
        <el-form-item label="专业">
          <major-select
            v-model="formInline.class"
            :defaultLabel="formInline.class"
            placeholder="请选择专业"
            :dept_no="''"
            valueField="no"
          />
        </el-form-item>

        <el-form-item label="年级">
          <dict-select
            v-model="formInline.grade"
            placeholder="请选择年级"
            type="stu_grade"
          />
        </el-form-item>

        <el-form-item label="时间范围">
          <el-date-picker
            v-model="formInline.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button @click="resetForm('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="box">
      <h4>数据快览</h4>
      <el-row>
        <!--   :span="24" -->
        <el-col
          v-for="(item, index) in number"
          :key="index"
          class="box-content"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <div
            class="box-content-item"
            v-for="(option, count) in item"
            :key="count"
          >
            <div class="h2" :class="{ active: count == 0 }">
              {{ option.value }}
            </div>
            <span>{{ option.label }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        v-for="item in dormRegiste"
        :key="item.title"
      >
        <div class="business">
          <h4>{{ item.title }}</h4>
          <div class="business-content">
            <div
              class="business-content-item"
              v-for="(option, count) in item.number"
              :key="count"
            >
              <div class="h2" :class="{ active2: count == 0 }">
                {{ option.value }}
              </div>
              <span>{{ option.label }}</span>
            </div>
          </div>
          <el-image
            class="business-img"
            fit="cover"
            :src="item.icon"
          ></el-image>
        </div>
      </el-col>
    </el-row>

    <div class="sale">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <div class="saleTop">
            实时销假逾期 <i class="el-icon-question"></i>
          </div>
          <div class="saleOfLeave">
            <vue-seamless-scroll :data="saleOfLeave">
              <div class="saleOfLeave-content">
                <div
                  v-for="item in saleOfLeave"
                  class="saleOfLeave-content-item"
                >
                  <div>{{ item.name }}</div>
                  <div>{{ item.stuId }}</div>
                  <div>{{ item.beOverdue | convertFromMilliseconds() }}</div>
                  <div>{{ item.major }}</div>
                  <div>累计{{ item.cumulative }}次</div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle1.titleName"
            :centerTage="circle1.centerTage"
            :centerName="circle1.centerName"
            :allPel="circle1.allPel"
            :dataValue="circle1.dataValue"
          ></VueRoun>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle2.titleName"
            :centerTage="circle2.centerTage"
            :centerName="circle2.centerName"
            :allPel="circle2.allPel"
            :dataValue="circle2.dataValue"
          ></VueRoun>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="sale-btn">
            <div class="content">
              查看实时休假中人员<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="sale mt20">
      <el-row class="mt20">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueMany :title="many1.title" :dataValue="many1.dataValue"></VueMany>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueMany
            style="border-left: 1px solid #ccc; border-right: 1px solid #ccc"
            :title="many2.title"
            :dataValue="many2.dataValue"
          ></VueMany>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueMany :title="many3.title" :dataValue="many3.dataValue"></VueMany>
        </el-col>
      </el-row>
    </div>

    <div class="sale mt20">
      <el-row class="mt20">
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle3.titleName"
            :centerTage="circle3.centerTage"
            :centerName="circle3.centerName"
            :allPel="circle3.allPel"
            :dataValue="circle3.dataValue"
          ></VueRoun>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle4.titleName"
            :centerTage="circle4.centerTage"
            :centerName="circle4.centerName"
            :allPel="circle4.allPel"
            :dataValue="circle4.dataValue"
          ></VueRoun>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle5.titleName"
            :centerTage="circle5.centerTage"
            :centerName="circle5.centerName"
            :allPel="circle5.allPel"
            :dataValue="circle5.dataValue"
          ></VueRoun>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle6.titleName"
            :centerTage="circle6.centerTage"
            :centerName="circle6.centerName"
            :allPel="circle6.allPel"
            :dataValue="circle6.dataValue"
          ></VueRoun>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle7.titleName"
            :centerTage="circle7.centerTage"
            :centerName="circle7.centerName"
            :allPel="circle7.allPel"
            :dataValue="circle7.dataValue"
          ></VueRoun>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <VueRoun
            :titleName="circle8.titleName"
            :centerTage="circle8.centerTage"
            :centerName="circle8.centerName"
            :allPel="circle8.allPel"
            :dataValue="circle8.dataValue"
          ></VueRoun>
        </el-col>
      </el-row>
    </div>

    <div class="sale mt20">
      <VueColumn></VueColumn>
    </div>
  </div>
</template>

<script>
import VueRoun from "./components/roundn.vue";
import VueMany from "./components/manyTimes.vue";
import VueColumn from "./components/columnar.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "Index",
  components: { VueRoun, VueMany, VueColumn, vueSeamlessScroll },
  data() {
    //
    return {
      title: process.env.VUE_APP_TITLE,
      subtitle: process.env.VUE_APP_SUB_TITLE,
      number: [
        [
          { value: 68, label: "请假中人数" },
          { value: 48, label: "男生人数" },
          { value: 20, label: "女生人数" },
          { value: 6, label: "今日请假人数" },
        ],
        [
          { value: 10, label: "销假逾期人数" },
          { value: 8, label: "逾期男生人数" },
          { value: 2, label: "逾期男生人数" },
          { value: 8, label: "今日销假人数" },
        ],
      ],
      dormRegiste: [
        {
          number: [
            { value: 18, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "请假业务",
          icon: require("@/assets/dorm/qing.png"),
        },
        {
          number: [
            { value: 12, label: "待处理" },
            { value: 1823, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 1823, label: "抄送我的" },
          ],
          title: "销假业务",
          icon: require("@/assets/dorm/xiao.png"),
        },
      ],
      formInline: {
        time: "",
        grade: "",
        class: "",
        college: "",
      },
      saleOfLeave: [
        {
          name: "张三丰",
          stuId: "2451100609",
          beOverdue: "302400000",
          major: "通信工程",
          cumulative: "3",
        },
        {
          name: "李四娘",
          stuId: "2451100609",
          beOverdue: "295200000",
          major: "汉语言文学",
          cumulative: "3",
        },
        {
          name: "张杰",
          stuId: "2451100609",
          beOverdue: "194400000",
          major: "通信工程",
          cumulative: "3",
        },
        {
          name: "安逸",
          stuId: "2451100609",
          beOverdue: "144000000",
          major: "通信工程",
          cumulative: "3",
        },
        {
          name: "幸好",
          stuId: "2451100609",
          beOverdue: "129600000",
          major: "通信工程",
          cumulative: "3",
        },
        {
          name: "开心",
          stuId: "2451100609",
          beOverdue: "129600000",
          major: "通信工程",
          cumulative: "3",
        },
      ],

      // 多次请假,多次离校,多次销假逾期
      many1: {
        title: "多次请假",
        dataValue: [
          {
            name: "张三丰",
            stuId: "2451100609",
            count: 5,
            major: "通信工程",
            cumulative: "3",
            timer: "30天3小时",
          },
          {
            name: "李四娘",
            stuId: "2451100609",
            count: 4,
            major: "汉语言文学",
            cumulative: "3",
            timer: "18小时",
          },
          {
            name: "张杰",
            stuId: "2451100609",
            count: 4,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "安逸",
            stuId: "2451100609",
            count: 3,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "幸好",
            stuId: "2451100609",
            count: 2,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
        ],
      },
      many2: {
        title: "多次离校",
        dataValue: [
          {
            name: "张三丰",
            stuId: "2451100609",
            count: 5,
            major: "通信工程",
            cumulative: "3",
            timer: "30天3小时",
          },
          {
            name: "李四娘",
            stuId: "2451100609",
            count: 4,
            major: "汉语言文学",
            cumulative: "3",
            timer: "18小时",
          },
          {
            name: "张杰",
            stuId: "2451100609",
            count: 4,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "安逸",
            stuId: "2451100609",
            count: 3,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "幸好",
            stuId: "2451100609",
            count: 2,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
        ],
      },
      many3: {
        title: "多次销假逾期",
        dataValue: [
          {
            name: "张三丰",
            stuId: "2451100609",
            count: 5,
            major: "通信工程",
            cumulative: "3",
            timer: "30天3小时",
          },
          {
            name: "李四娘",
            stuId: "2451100609",
            count: 4,
            major: "汉语言文学",
            cumulative: "3",
            timer: "18小时",
          },
          {
            name: "张杰",
            stuId: "2451100609",
            count: 4,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "安逸",
            stuId: "2451100609",
            count: 3,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "幸好",
            stuId: "2451100609",
            count: 2,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
        ],
      },

      // 圆圈数据,可做调整
      circle1: {
        titleName: "实时休假",
        centerTage: "4.1%",
        centerName: "请假率",
        allPel: "总人数\u0020\u0020\u0020\u00202000",
        dataValue: [
          {
            value: 2000,
            name: "非请假人数 2000",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 86,
            name: "请假人数 86",
            itemStyle: { color: "#fde932" },
          },
          {
            value: 18,
            name: "销假逾期人数 18",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle2: {
        titleName: "实时离校",
        centerTage: "86.7%",
        centerName: "请假离校率",
        allPel: "总休假人数\u0020\u0020\u0020\u002015",
        dataValue: [
          {
            value: 2,
            name: "非离校休假人数 2",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 13,
            name: "离校休假人数 13",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle3: {
        titleName: "请假类型",
        centerTage: "304",
        centerName: "总请假申请数",
        allPel: "",
        dataValue: [
          {
            value: 200,
            name: "事假申请 200",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 86,
            name: "病假申请 86",
            itemStyle: { color: "#fde932" },
          },
          {
            value: 18,
            name: "其他类型申请 18",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle3: {
        titleName: "请假类型",
        centerTage: "304",
        centerName: "总请假申请数",
        allPel: "",
        dataValue: [
          {
            value: 200,
            name: "事假申请 200",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 86,
            name: "病假申请 86",
            itemStyle: { color: "#fde932" },
          },
          {
            value: 18,
            name: "其他类型申请 18",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle4: {
        titleName: "请假审批",
        centerTage: "100%",
        centerName: "审批通过率",
        allPel: "总请假人数\u0020\u0020\u0020\u002010",
        dataValue: [
          {
            value: 10,
            name: "审批通过 10",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 0,
            name: "审批驳回 0",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle5: {
        titleName: "审批时长",
        centerTage: "74.1%",
        centerName: "≤2小时审批率",
        // allPel: "总请假人数\u0020\u0020\u0020\u002010",
        allPel: "",
        dataValue: [
          {
            value: 200,
            name: "≤2小时审批率 200",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 40,
            name: "2小时≤12小时 40",
            itemStyle: { color: "#b2def9" },
          },
          {
            value: 20,
            name: "12小时≤24小时 20",
            itemStyle: { color: "#fde932" },
          },
          {
            value: 10,
            name: "24小时以上 10",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle6: {
        titleName: "请假时长",
        centerTage: "60.6%",
        centerName: "≤1天的请假占比",
        // allPel: "总请假人数\u0020\u0020\u0020\u002010",
        allPel: "",
        dataValue: [
          {
            value: 7,
            name: "≤1天的申请 7",
            itemStyle: { color: "#39cf05" },
          },
          {
            value: 1,
            name: "1天~3天的申请 1",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 2,
            name: "3天~7天的申请 2",
            itemStyle: { color: "#fde932" },
          },
          {
            value: 1,
            name: "≥七天的申请 1",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle7: {
        titleName: "离校情况",
        centerTage: "18.2%",
        centerName: "请假离校率",
        allPel: "总请假申请\u0020\u0020\u0020\u002011",
        dataValue: [
          {
            value: 9,
            name: "非离校申请 9",
            itemStyle: { color: "#68a6ff" },
          },
          {
            value: 2,
            name: "离校申请 2",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
      circle8: {
        titleName: "销假逾期",
        centerTage: "20%",
        centerName: "销假逾期率",
        allPel: "需销假的请假申请\u0020\u0020\u0020\u002050",
        dataValue: [
          {
            value: 9,
            name: "销假及时 40",
            itemStyle: { color: "#b2def9" },
          },
          {
            value: 2,
            name: "销假逾期 10",
            itemStyle: { color: "#ee7c51" },
          },
        ],
      },
    };
  },
  filters: {
    convertFromMilliseconds(milliseconds) {
      // 计算毫秒到天数和小时数的比例
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const millisecondsPerHour = 60 * 60 * 1000;

      // 将毫秒转换成天数和小时数
      const days = Math.floor(milliseconds / millisecondsPerDay);
      const remainingMilliseconds = milliseconds % millisecondsPerDay;
      const hours = Math.floor(remainingMilliseconds / millisecondsPerHour);
      return "已逾期" + days + "天" + hours + "小时";
    },
  },
  mounted() {},
  beforeUnmount() {
    this.stopLiMarquee();
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    resetForm() {
      for (let i in this.formInline) {
        this.formInline[i] = "";
      }
    },
    convertToMilliseconds(days, hours) {
      // 一天有24小时，一小时有60分钟，一分钟有60秒，一秒有1000毫秒
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const millisecondsPerHour = 60 * 60 * 1000;

      // 将天数和小时数转换成毫秒
      const totalMilliseconds =
        days * millisecondsPerDay + hours * millisecondsPerHour;

      return totalMilliseconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}
.dashboard-editor-container {
  width: 100%;
  height: 100%;
  background: #f6f8f9 !important;
}
.active {
  color: red !important;
}
.active2 {
  color: #0099ff !important;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 3px 32px 20px;
  box-sizing: border-box;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &:nth-child(1) {
      border-right: 1px solid #f9f9f9;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }
}

.business {
  background-color: #fff;
  border-radius: 12px;
  padding: 3px 3px 32px 20px;
  box-sizing: border-box;
  margin-top: 30px;
  position: relative;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }

  &-img {
    position: absolute;
    right: 0px;
    top: -20px;
    width: 90px;
    height: 80px;
  }
}

.sale {
  background-color: #fff;
  border-top: 1p solid #fff;
  margin-top: 20px;
  padding: 0 65px;
  box-sizing: border-box;
  .saleTop {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 16px;
  }
  .saleOfLeave {
    padding: 0px 20px;

    @keyframes animame {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(-50%);
      }
    }
    height: 40vh;
    overflow: hidden;
    &-content {
      // animation: animame 5s linear infinite;
      &-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        div {
          width: 30%;
          &:nth-child(1) {
            color: rgba(24, 144, 255, 1);
            font-size: 16px;
            font-weight: bold;
          }
          &:nth-child(2) {
            color: rgba(165, 165, 165, 1);
          }
          &:nth-child(3) {
            color: #ff0000;
            text-align: right;
          }
          &:nth-child(4),
          &:nth-child(5) {
            color: rgba(165, 165, 165, 1);
            margin-top: 5px;
          }
          &:nth-child(5) {
            text-align: right;
          }
        }
      }
    }
  }

  &-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    .content {
      background-color: rgba(236, 237, 241, 0.5);
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      margin: 20px;
      padding: 10px 8vw;
      border-radius: 5px;
    }
  }
}
</style>
