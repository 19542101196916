<template>
  <div
    style="
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    "
  >
    <div
      style="
        padding-top: 20px;
        font-size: 16px;
        text-align: center;
        width: 350px;
      "
    >
      {{ titleName }} <i class="el-icon-question"></i>
    </div>
    <div ref="echartsContainer" style="width: 350px; height: 350px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "roundn",
  props: {
    titleName: {
      type: String,
      default: "实时休假",
    },
    centerTage: {
      type: String,
      default: "4.1%",
    },
    centerName: {
      type: String,
      default: "请假率",
    },
    allPel: {
      type: String,
      default: "总人数\u0020\u0020\u0020\u00202000",
    },
    dataValue: {
      type: Array,
      default: () => [
        {
          value: 2000,
          name: "非请假人数 2000",
          itemStyle: { color: "#68a6ff" },
        },
        {
          value: 86,
          name: "请假人数 86",
          itemStyle: { color: "#fde932" },
        },
        {
          value: 18,
          name: "销假人数 18",
          itemStyle: { color: "#ee7c51" },
        },
      ],
    },
  },
  mounted() {
    var myChart = echarts.init(this.$refs.echartsContainer);
    myChart.setOption({
      // ECharts 配置项
      //   title: {
      //     text: "实时休假",
      //   },
      tooltip: {
        trigger: "item",
        show: false, // 关闭 tooltip 显示
      },
      graphic: {
        elements: [
          // 添加 "4.1%"
          {
            type: "text",
            left: "center",
            top: "30%", // 根据需要调整位置
            style: {
              text: this.centerTage,
              fill: "#666",
              fontSize: 40,
              textAlign: "center",
              textBaseline: "middle",
            },
          },
          // 添加 "请假率"
          {
            type: "text",
            left: "center",
            top: "45%", // 根据需要调整位置
            style: {
              text: this.centerName,
              fill: "#666",
              fontSize: 18,
              textAlign: "center",
              textBaseline: "middle",
            },
          },
          {
            type: "text",
            left: "center",
            top: "74%", // 根据需要调整位置
            style: {
              text: this.allPel,
              fill: "#333",
              fontSize: 16,
              textAlign: "center",
              textBaseline: "middle",
            },
          },
        ],
      },
      legend: {
        orient: "vertical", // 设置图例为垂直方向
        x: "center", // 图例水平居中
        y: "bottom", // 图例放在底部
      },
      series: [
        {
          name: "人数",
          type: "pie",
          radius: ["43%", "60%"],
          center: ["50%", "40%"], // 调整中心位置
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.dataValue,
        },
      ],
    });
  },
};
</script>
