<template>
  <div class="many">
    <div class="h2">
      <div class="line"></div>
      <div>{{ title }}</div>
      <div class="line"></div>
    </div>
    <div class="many-content">
      <div class="many-content-box">
        <div v-for="item in dataValue" class="many-content-box-item">
          <div>{{ item.name }}</div>
          <div>{{ item.stuId }}</div>
          <div>
            <strong style="font-size: 18px">{{ item.count }}</strong> 次
          </div>
          <div>{{ item.major }}</div>
          <div>累计{{ item.timer }}</div>
        </div>
      </div>
    </div>

    <div class="more">查看更多</div>
  </div>
</template>

<script>
export default {
  name: "manyTimes",
  props: {
    title: {
      type: String,
      default: "多次请假",
    },
    dataValue: {
      type: Array,
      default: () => {
        [
          {
            name: "张三丰",
            stuId: "2451100609",
            count: 5,
            major: "通信工程",
            cumulative: "3",
            timer: "30天3小时",
          },
          {
            name: "李四娘",
            stuId: "2451100609",
            count: 4,
            major: "汉语言文学",
            cumulative: "3",
            timer: "18小时",
          },
          {
            name: "张杰",
            stuId: "2451100609",
            count: 4,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "安逸",
            stuId: "2451100609",
            count: 3,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
          {
            name: "幸好",
            stuId: "2451100609",
            count: 2,
            major: "通信工程",
            cumulative: "3",
            timer: "3小时",
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.line {
  display: inline-block;
  width: 40px;
  height: 1px;
  background: black;
  margin: 0 5px;
}

.many {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  .h2 {
    position: relative;
    text-align: center;
    display: inline;
    width: 100%;
    color: black;
    font-weight: bold;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    // &::before {
    //   content: "";
    //   display: inline-block;
    //   width: 40px;
    //   height: 1px;
    //   background: black;
    //   position: absolute;
    //   top: 50%;
    //   left: 30%;
    //   transform: translateY(-50%);
    // }
    // &::after {
    //   content: "";
    //   display: inline-block;
    //   width: 40px;
    //   height: 1px;
    //   background: black;
    //   position: absolute;
    //   top: 50%;
    //   right: 30%;
    //   transform: translateY(-50%);
    // }
  }
  &-content {
    width: 100%;
    &-box {
      width: 100%;
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        div {
          width: 30%;
          &:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
            color: #1890ff;
          }
          &:nth-child(2) {
            color: #a5a5a5;
          }
          &:nth-child(3) {
            color: #ff0000;
            font-size: 16px;
            text-align: right;
          }
          &:nth-child(4) {
            color: #a5a5a5;
            margin-top: 10px;
            text-align: left;
          }
          &:nth-child(5) {
            color: #a5a5a5;
            margin-top: 10px;
            text-align: right;
          }
        }
      }
    }
  }

  .more {
    width: 206px;
    height: 41px;
    background-color: rgba(236, 237, 241, 0.5);
    font-size: 18px;
    line-height: 41px;
    text-align: center;
    margin-top: 30px;
    border-radius: 5px;
  }
}
</style>
